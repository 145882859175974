export const NATIVE_TOKEN_DECIMALS = 6;
export const LP_TOKEN_DECIMALS = 6;
export const COMMISSION_RATE = 0.02; // 2%

export const IS_DEV =
  window.location.hostname.includes('development') || window.location.hostname.includes('localhost');
export const IS_STAGING = window.location.hostname.includes('staging');
export const IS_PROD = window.location.hostname.includes('terraport.finance');
export const IS_LOCAL = window.location.hostname.includes('localhost');

export const classicNetwork = {
  name: 'testnet',
  chainID: 'rebel-2',
  rpc: 'https://rpc.luncblaze.com',
  fcd: 'https://lcd.luncblaze.com',
  explorer: 'https://wallet.terra-classic.io/explorer/',
  id: 'rebel-2',
  gasPrices: { uusd: 0.15 },
  fee: { gasPrice: 0.15, amount: 100000 }, // 0.1 USTC
  v3FactoryContract: 'terra18yk94hclruvxe6546g0685hlwgsqr3dwz0v6dl6rf9slgmzhgcfq36dy3c',
  factoryContract: 'terra1572et5jv9jra9tp29qhek2w0tuw347dppmu443y4drxzyxfkwteqw8fwen',
  routerContract: 'terra1l4fuw8ypsydm73p36qrclygtwehjpjucx0h588qemgamqmswt96sjpfvxp',
  vestingContract: 'NP',
  tokenContract: 'terra1rt6rr02h93nd2g5vn9gv0mchu0wpug9c8xml9ukkgnu5wl25edcs0lf70f',
  stakingContract: 'NP',

  liquidstakinghubContract: 'NP',
  liquidstakingrewardContract: 'NP',
  liquidstakingFarm: 'NP',
  liquidstakingLPToken: 'NP',
  liquidstakingBondedLunaContract: 'NP',
  liquidStakingPair: 'NP',

  vestingContractInvestors: 'NP',
  governanceContract: 'terra1n69ya49z6xgaatprpm30xcxj3gd8mlw5ffztahdftrur742fz4jq3r92jg',
  burnContract: 'NP',
  allowedPairContracts: [],
};

export const terraportNetworks = {
  classic: classicNetwork,
};
