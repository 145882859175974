// import { checkVestingVisible } from 'api/rpc/vesting';
import { Dictionary } from 'lodash';
import { classicNetwork } from './networks';

// TODO dovrebbe essere un file .ts
// TODO magari dividere per aree logiche

export const TX_STATE = {
  INIT: 'init',
  START: 'start',
  PENDING: 'pending',
  COMPLETE: 'complete',
  ERROR: 'error',
};

export const SALE_TYPE = {
  PRIVATE: 'Private',
  INVESTORS: 'Investors',
  PRESALE1: 'Presale1',
  PRESALE2: 'Presale2',
  REFERRAL: 'Referral',
  RELAUNCH: 'Investors',
};

export type VestingSaleType = 'Private' | 'Investors' | 'Presale1' | 'Presale2' | 'Referral';

export type VestingData = {
  dailyReward?: string;
  availableAmount?: string;
  claim: {
    total?: string;
    amount?: string;
    claimedTimestamp?: string;
    rewardTimestamp?: string;
  };
  merkleRoot: Dictionary<string>;
  locked?: string;
  unlocked?: string;
  isInvestor: boolean;
};

export type VestingRequestData = {
  saleType: VestingSaleType;
  originalName: string;
  address: string;
  merkleRoot?: Dictionary<string>;
};

export type VestingEncodedItem = {
  address: string;
  amount: number;
};

export const CURRENCY = {
  LUNC: 'terra-luna',
  USTC: 'terrausd',
  TERRA: 'TERRA',
  USDT: 'usdt',
  USDC: 'usdc',
};

export const ONE_DAY = 24 * 60 * 60; // 1 Day to Seconds
export const ONE_MONTH = 30 * ONE_DAY; // 1 Month(30 Days) to Seconds
export const ONE_YEAR = 12 * ONE_MONTH; // 1 Year(12 Months) to Seconds

export const ONE_YEAR_MONTH = 12;
export const ONE_MONTH_DAY = 30;

export const STAKING_MODAL = {
  STAKING: 0,
  UNSTAKING: 1,
};

export const STAKING_TYPE = {
  NONE: -1,
  TYPE1: 0,
  TYPE2: 1,
  TYPE3: 2,
  TYPE4: 3,
  TYPE5: 4,
  TYPE6: 5,
};

export const GRAPH_PERIODS_TYPES = {
  HOUR: '1H',
  DAY: '1D',
  WEEK: '1W',
  MONTH: '1M',
  YEAR: '1Y',
};
export const GRAPH_PERIODS = Object.values(GRAPH_PERIODS_TYPES);

export const BLACKLIST = [
  'terra1hwretudj8n6uyscxgjw85rfxdaz645xrlpzwy9',
  'terra1ka9y2jpqehwruhm9wxem7zy9dfrga3akyklmv6',
  'terra1f6lxgva5u93q7uclsgk3k4th3m72w63s79h5kx',
  'terra1v08a9t3faglh7f0zw6e32cmx8rzm68sdjkvscr',
];

export const WALLET_STATUS = {
  CONNECTED: 'CONNECTED',
  NOT_CONNECTED: 'NOT_CONNECTED',
  INITIALIZING: 'INITIALIZING',
};

export const DEFAULT_CHAIN = classicNetwork.chainID;

export const DEFAULT_RPC_ENDPOINT = classicNetwork.rpc;

export const TERRA_TOKEN_ADDRESS = classicNetwork.tokenContract;

export enum CODE_ID {
  TOKEN = '8266',
  PAIR = '8260',
  FARM = '8280',
}
