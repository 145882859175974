import { Transition, Input } from '@headlessui/react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { baseUrl } from 'api/terraport/base';
import axios from 'axios';
import clsx from 'clsx/lite';
import { map } from 'lodash';
import { FC, useState, useRef, RefObject } from 'react';
import { createPortal } from 'react-dom';
import { FaSearch } from 'react-icons/fa';
import { FaChevronDown, FaX } from 'react-icons/fa6';
import { pairState } from 'state/trade';
import { useAddress } from 'state/wallet-state';
import { Asset } from 'types/pairs';
import { PaginationObserver } from './data-grid';

const PG_LIMIT = 100;

const ToggleSwitch: FC<{
  value: boolean;
  onChange: (value: boolean) => void;
  label: string;
}> = ({ value, onChange, label }) => (
  <div className="flex flex-row items-center gap-1">
    <div
      className={clsx(
        value ? 'bg-accent-btn' : 'bg-gr2',
        'relative h-4 w-6 cursor-pointer rounded-full p-0.5',
      )}
      onClick={() => onChange(!value)}>
      <div className={clsx(value ? 'ml-2' : 'ml-0', 'size-3 rounded-full bg-background3 transition-all')} />
    </div>
    <p className="text-xs text-white">{label}</p>
  </div>
);

export const AdvAssetSelect: FC<{
  asset: Asset | null;
  omitToken: Asset | null;
  onChange: (value: Asset) => void;
  portalRef?: RefObject<HTMLElement>;
}> = ({ asset, onChange, portalRef, omitToken }) => {
  const [open, setOpen] = useState(false);
  const degen = pairState.use.degen();
  const [search, setSearch] = useState('');

  const handleChange = (asset: Asset) => {
    setSearch('');
    onChange(asset);
    setOpen(false);
  };

  const defaultRef = useRef<HTMLDivElement>(null);
  const address = useAddress();
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryFn: async ({ pageParam }) => {
      const resp = await axios.get<Asset[]>(`${baseUrl}/degen/tokens`, {
        params: {
          q: search,
          creator_address: address,
          whitelisted: !degen,
          offset: pageParam * PG_LIMIT,
          limit: PG_LIMIT,
        },
      });
      return resp.data;
    },
    queryKey: ['tokens', search, degen, address],
    initialPageParam: 0,
    getNextPageParam: (lastPage, _, lastPageParam) => {
      if (lastPage.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
  });

  return (
    <>
      <div
        ref={defaultRef}
        onClick={() => {
          setOpen(true);
        }}
        className="relative box-border flex h-full w-44 cursor-default flex-row items-center justify-between gap-2 rounded-l-2xl bg-background4 p-1 pl-2 pr-4 text-left text-sm focus:bg-background4 focus:outline-none">
        {asset ? (
          <>
            {/* asset.token.img serve per quando faccio provide in dev farm */}
            <img src={asset.img ?? asset.token.img} className="!h-8 !w-8 rounded-full" />
            <div className="mr-auto flex flex-col">
              <p className="font-logo text-sm">{asset.denom}</p>
              <p className="text-[10px] text-white/80">{asset.name}</p>
            </div>
            <div
              className="center absolute -right-[18px] size-9 p-1"
              style={{
                backgroundImage: 'url(/assets/svg/bubble-vert.svg)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
              }}>
              <FaChevronDown
                className="size-4 min-w-4 rounded-full bg-accent-btn fill-text-accent"
                aria-hidden="true"
              />
            </div>
          </>
        ) : (
          <p className="pl-4 text-white/40">Select a token...</p>
        )}
      </div>
      {createPortal(
        <Transition
          as={'div'}
          appear
          show={open}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          enter="transition ease-out duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          className="no-scrollbar absolute inset-2 z-10 mt-12 overflow-auto rounded-xl bg-background3 text-base shadow-[0_0_25px_0_rgb(0_0_0_/_0.1)] shadow-background3 ring-1 ring-black/5 focus:outline-none">
          <div
            className="sticky top-0 z-[2] flex flex-col items-stretch justify-start gap-2 rounded-t-xl bg-background2 p-4 pt-6"
            onClick={(e) => e.stopPropagation()}>
            <div className="flex w-full flex-row items-center justify-between gap-2">
              <div className="flex grow items-center justify-start gap-2 rounded-full bg-gr2 p-2">
                <FaSearch className="text-background4" />
                <Input
                  className="grow border-none bg-transparent text-background4 focus:outline-none"
                  autoFocus
                  defaultValue={''}
                  onChange={(e) => setSearch(e.target.value.toLowerCase())}
                />
              </div>
              <FaX className="text-white" onClick={() => setOpen(false)} />
            </div>
            <div className="flex w-full flex-row items-center justify-start gap-2">
              <ToggleSwitch value={degen} onChange={pairState.set.degen} label="Degen Mode" />
            </div>
          </div>
          {map(data?.pages, (page) =>
            map(page, (option) =>
              option.contract_addr !== omitToken?.contract_addr ? (
                <div
                  key={option.contract_addr}
                  className={clsx(
                    'from-background2 hover:bg-gradient-to-r',
                    option.contract_addr === asset?.contract_addr ? 'bg-background-3' : 'bg-background-4',
                    'hover:bg-background-3 relative box-border flex w-80 max-w-full select-none flex-row items-center gap-2 overflow-x-hidden p-2 text-sm text-white',
                  )}
                  onClick={() => handleChange(option)}>
                  <img src={option.img} className="!size-8 rounded-full" />
                  <div className="flex w-full flex-col items-start">
                    <div className="flex flex-row items-baseline gap-1">
                      <p className="font-logo text-sm">{option.denom}</p>
                      {option.verified && (
                        <p className="center h-4 rounded-full bg-theme-yellow1/80 px-2 text-[10px] font-bold text-text-accent">
                          Verified
                        </p>
                      )}
                      {option.created && (
                        <p className="center h-4 rounded-full bg-gr2 px-2 text-[10px] font-bold">Created</p>
                      )}
                      {option.imported && (
                        <p className="center h-3 rounded-full bg-gr1 px-2 text-[10px] font-bold">Imported</p>
                      )}
                    </div>
                    <p className="text-xs text-white/80">{option.name}</p>
                  </div>
                </div>
              ) : null,
            ),
          )}
          <PaginationObserver hasNext={hasNextPage} addPage={fetchNextPage} />
        </Transition>,
        portalRef?.current ?? defaultRef.current?.parentElement ?? document.body,
      )}
    </>
  );
};
