import { useQuery } from '@tanstack/react-query';
import { convertDenomToMicroDenom } from 'helper/utils';
import { Candle, ExchangeResponse } from 'Models/Exchange';
import { baseUrl } from 'api/terraport/base';
import axios from 'axios';
import { filter, keyBy } from 'lodash';
import { pairState } from 'state/trade';
import { Asset } from 'types/pairs';

type SwapOperationResponse = {
  simulation: {
    route_microdenoms: string[];
    amount: number;
    source_dollar_value: number;
    dest_dollar_value: number;
    delta_percent: number;
    ratio: number;
  };
  simulate_swap_message: {
    simulate_swap_operations: {
      offer_amount: string;
      operations: [
        {
          terra_port: {
            offer_asset_info:
              | {
                  token: {
                    contract_addr: string;
                  };
                }
              | {
                  native_token: {
                    denom: string;
                  };
                };
            ask_asset_info:
              | {
                  native_token: {
                    denom: string;
                  };
                }
              | {
                  token: {
                    contract_addr: string;
                  };
                };
          };
        },
      ];
    };
  };
};

export const useSimulateSwapOperationQuery = (
  fromAsset: Asset | null,
  toAsset: Asset | null,
  amount: number,
) => {
  const degen = pairState.use.degen();
  return useQuery({
    enabled: !!(fromAsset && toAsset) && amount > 0,
    queryKey: ['swap-ops', amount, fromAsset?.contract_addr, toAsset?.contract_addr, degen], //no caching
    retry: false,
    queryFn: async () => {
      const { data } = await axios
        .get<SwapOperationResponse>(`${baseUrl}/${degen ? 'degen/' : ''}router/simulate-swap`, {
          params: {
            source: fromAsset?.contract_addr,
            dest: toAsset?.contract_addr,
            amount: convertDenomToMicroDenom(amount),
          },
        })
        .catch(({ response }) => {
          throw new Error(response.data.error);
        });
      if (typeof data === 'string') {
        throw new Error(`Swap error: ${data}`);
      }
      return data;
    },
  });
};

// from format: 2024-04-09T17:07:13.065Z
export const useGetExchangeCandlesQuery = ({
  coinPay,
  coinBuy,
  from,
  to,
  step,
}: {
  coinPay?: Asset | null;
  coinBuy?: Asset | null;
  from: Date;
  to: Date;
  step: number;
}) => {
  return useQuery<{
    candles: Record<string, Candle>;
    realtimeData?: ExchangeResponse['realtimeData'];
    key: string | null;
    fallback?: true;
  }>({
    enabled: !!(coinPay && coinBuy && from && to),
    queryKey: ['exchange', coinPay?.contract_addr, coinBuy?.contract_addr, from.getTime(), to.getTime()],
    refetchInterval: 30000,
    retry: false,
    queryFn: async () => {
      const { data } = await axios.get<ExchangeResponse>(`${baseUrl}/analytics/exchange`, {
        params: {
          pay_address: coinPay?.contract_addr,
          buy_address: coinBuy?.contract_addr,
          from: from?.toISOString(),
          to: to?.toISOString(),
          step: `${step}s`,
        },
      });
      if ('error' in data) {
        console.warn(data.error);
        return { candles: {}, key: null };
      }
      return {
        key: `${coinPay?.contract_addr ?? ''} ${coinBuy?.contract_addr}`,
        realtimeData: data.realtimeData,
        candles: keyBy(
          filter(data.candles, (c) => typeof c.y !== 'string' && typeof c.y_dollar !== 'string'),
          (c) => c.x / 1000,
        ),
      };
    },
  });
};
