import * as Sentry from '@sentry/react';
import i18n from 'i18next';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { resources } from 'i18n';
import { IS_DEV } from 'consts/networks';

import 'react-toastify/dist/ReactToastify.minimal.css';
import { suggestChain } from 'helper/suggest-chain';

suggestChain();

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources,
    defaultNS: 'all',
    // ns: Object.keys(resources.en),
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    debug: true,
  });

Sentry.init({
  enabled: !IS_DEV,
  dsn: 'https://db52dab62a493a8b2861949dd716c3ce@o4507464618016768.ingest.de.sentry.io/4507464965488720',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  environment: window.location.hostname,
  denyUrls: ['localhost'],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['terraport.finance'],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = document.getElementById('root');

if (root) {
  ReactDOM.createRoot(root).render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
  );
}
